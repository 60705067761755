export const dashBoardImg = require("../assets/images/Dashboard.png");
export const afloatImg = require("../assets/images/Afloat.png");
export const ganttClone1 = require("../assets/images/gantt-clone1.png");
export const ganttClone2 = require("../assets/images/gantt-clone2.png");
export const adminUi = require("../assets/images/admin ui.png");
export const adminUi1 = require("../assets/images/admin ui1.png");
export const adminUi2 = require("../assets/images/admin ui2.png");
export const adminUi3 = require("../assets/images/admin ui3.png");
export const adminUi4 = require("../assets/images/admin ui4.png");
export const speedDial1 = require("../assets/images/speed dial1.png");
export const dummyImg = require("../assets/images/dummy.jpeg");
